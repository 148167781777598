import React from "react";
import axios from "axios";
import NoRecords from "../no-records";
import Loading from "../loading";
import InfiniteScroll from 'react-infinite-scroll-component';
import "../../assets/css/demo-style-4.css";
// import team1 from "../../images/team.jpg";
import DemoContributorItem1 from "./JfeContributorItem";

var Api_url = process.env.GATSBY_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
  headers: {
    "Content-Type": "application/json; text/html; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};

class Team extends React.Component {

  constructor() {
    super();
    this.state = {
      Records: [],
      LoadingFlag: false,
      TotalRecords: 0,
      PageNo: 1,
      hasMore: true,
    }
  };

  handleChange(e) {
    // this.setState({ LomId: event.target.value });
    this.setState({ [e.target.name]: e.target.value });
  }

  getAllContributors = async () => {
    console.log("getAllContributors called ");

    var PageNo = this.state.PageNo;
    console.log("PageNo=" + PageNo);

    var url = Api_url + `/api/title/member/contributors-list?page=` + PageNo;
    console.log("url=" + url);
    var postData = {
      UserInterface: 1,
      TitleName: 15,
    };
    this.setState({ LoadingFlag: true });
    console.log("postData=" + JSON.stringify(postData));
    // this.setState({ isLoading: true });
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));

        var current_data = this.state.Records;
        var receive_data = res.data.TableContent.TableData;
        console.log("receive_data=" + JSON.stringify(receive_data));
        var result = current_data.concat(receive_data);
        var n1 = result.length;
        console.log("n1=" + n1);

        this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });

        var TotalPage = res.data.TableContent.TotalPage;
        if (TotalPage > this.state.PageNo) {
          this.setState({ PageNo: this.state.PageNo + 1 });
        } else {
          this.setState({ hasMore: false });
        }
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  componentDidMount() {
    console.log("componentDidMount called ");
    // window.location.replace('https://www.google.com')
    this.getAllContributors();
  }
  render() {
    return (
      <section className="team-area team-area2" id="demo-cards-4">
        <div className="container">
          <InfiniteScroll
            dataLength={this.state.Records.length} //This is important field to render the next data
            next={this.getAllContributors}
            hasMore={this.state.hasMore}
            loader={''}
            scrollThreshold={0.7}
            endMessage={''}
          >
            <div className="row team-content-wrap" style={{ width: '99.9%' }}>
              {this.state.TotalRecords > 0 ?
                this.state.Records.map(item => (
                  <div className="col-lg-3 col-sm-6">
                    <DemoContributorItem1 blogContent={item} />
                  </div>
                ))
                : (this.state.LoadingFlag ? '' : <NoRecords />)
              }
              <div className="col-lg-12 text-center">
                {this.state.LoadingFlag ? <Loading /> : ''}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </section>
    );
  }
};

export default Team;
