import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import JfEContributors from "../../components/contributors/JfeContributors";

const TeamPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | JFE Contributors">
            <NavOne />
            <PageHeader title="JFE Contributors" />
            <JfEContributors />
            <Footer />
        </Layout>
    );
};

export default TeamPage;
